import { DatePicker } from '@progress/kendo-react-dateinputs'
import { DropDownList } from '@progress/kendo-react-dropdowns'
import { Tooltip } from '@progress/kendo-react-tooltip'
import { RasaContext } from 'context'
import { addDays, addMinutes, parseISO, subDays } from 'date-fns'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import { isEmpty, isNil } from 'lodash'
import React from 'react'
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { formatDateForES, strfFormatDate } from 'shared_server_client/dates'

import { BaseClientEntity } from '../../generic/baseClientEntity'
import { AllComponentPropsWithModal, registerNewComponentWithModals } from '../../generic/genericRedux'
import { EntityFieldPropMapType, EntityPropsMap, RasaReactComponent } from '../../generic/rasaReactComponent'
import { DEFAULT_TIMEZONE } from '../../shared_server_client/constants'
import { RasaTimePicker } from '../time-picker/component'

import { showAppCue } from 'components/app-cues/actions'
import { APP_CUE_TYPE } from 'components/app-cues/constants'
import * as BannerActions from 'components/banner/actions'
import { BannerType } from 'components/banner/constants'
import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import { OnChangeEvent } from 'components/dropdown/component'
import * as Flash from 'components/flash'
import { Loading, SIZES } from 'components/loading'
import { RasaTimeZone } from 'components/time-zone/component'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import { Dataset, DatasetParam } from 'generic/dataset'
import { Roles } from 'shared/constants'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { ScheduleFrequency } from 'shared_server_client/constants'
import { formatSendDate, validateTz } from 'shared_server_client/dates'
import { getCurrentPlanMaxScheduleDays } from 'shared_server_client/types/billing_plan'
import * as GlobalConstants from '../../constants'
import './_styles.scss'
import * as Constants from './constants'
import { emptyScheduleSettings, ScheduleSettings as ScheduleSettingsData, WeekInfo } from './data'
import { FrequencySelector } from './frequencySelector'
import { ManualSendModal, ScheduleStartedModal } from './modals'
import { MultiDaySelector } from './multidaySelector'
import { MultiWeekSelector } from './multiweekSelector'
import { NextNewsletterSendComponent } from './nextNewsletter'
import './styles.css' // Use .scss instead
import * as Weekdays from './weekdays'
import * as weekOccurence from './weekOccurence'

export type ScheduleSettingsProps = AllComponentPropsWithModal<ScheduleSettingsData>

interface ScheduleSettingsState {
  activeSchedule: boolean
  billingPlan: any
  communityId: string
  companyInfoProvided: boolean
  hasEnoughContent: boolean
  isManualSendDisabled: boolean
  isScheduled: boolean
  isSending: boolean
  isSuperUser: boolean
  lastSend: string
  manualSendCutOffTime: string
  maxScheduleDays: number
  minimumArticlesCount: number
  modalType?: string
  nextIssue: any
  pauseScheduleConfirmed: boolean
  savedSchedule: boolean
  sendDate: Date
  sendMaximumDate: Date
  sendMinimumDate: Date
  sendMinimumTime: Date
  sendTime: string
  showPauseScheduleModal: boolean
  validOffset: boolean
}

export class ScheduleSettingsComponent extends RasaReactComponent<ScheduleSettingsProps, ScheduleSettingsState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore
  private scheduleId: number
  private oldProps: ScheduleSettingsData
  constructor(props: ScheduleSettingsProps) {
    super(props, 'schedule')
    this.setEntityPropsMap(this.createEntityPropsMap())
    this.state = {
      activeSchedule: null,
      billingPlan: [],
      communityId: null,
      companyInfoProvided: false,
      hasEnoughContent: false,
      isManualSendDisabled: false,
      isScheduled: null,
      isDirty: false,
      isLoading: true,
      isSaving: false,
      isSending: false,
      isSuperUser: false,
      lastSend: null,
      manualSendCutOffTime: null,
      maxScheduleDays: 2,
      minimumArticlesCount: null,
      nextIssue: null,
      pauseScheduleConfirmed: false,
      savedSchedule: null,
      sendDate: null,
      sendTime: null,
      sendMaximumDate: null,
      sendMinimumDate: null,
      sendMinimumTime: null,
      showPauseScheduleModal: false,
      validOffset: true,
    }
  }

  public componentDidMount = () => {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.person),
      this.sharedStore.getValue(SharedKeys.role),
    ]).then(([activeCommunity, person, role]) => {
      this.validateCompanyInfo(person, activeCommunity)
      if (person.accountExpired()) {
        this.props.push(DashboardMenuOption.dashboard)
      } else {
        if (activeCommunity.communityInfo && activeCommunity.communityInfo.data.schedule
          && activeCommunity.communityInfo.data.schedule.length > 0) {
          const { email_layouts, schedule } = activeCommunity.communityInfo.data
          this.scheduleId = schedule.filter((s: any) => s.is_primary)[0].id
          const emailLayoutId = email_layouts.filter((s: any) => s.is_active)[0].id
          const maxScheduleDayslimit =
          getCurrentPlanMaxScheduleDays(this.context.user.activeCommunity.billingInfo.currentPlan)
          Promise.all([
              this.getArticleList(activeCommunity.communityId),
              this.getMinimumArticlesCount(activeCommunity.communityId, emailLayoutId),
              this.loadRecord(activeCommunity.communityId, this.scheduleId),
              this.getNewsletterManualIssueCutOff(),
            ])
            .then(([articleList, minimumArticlesCount, _, manualIssueCutoff]) => {
              this.oldProps = this.props.data

              return this.setState({
                activeSchedule: !!activeCommunity._communityInfo.data.schedule[0].is_active,
                communityId: activeCommunity.communityId,
                hasEnoughContent: articleList.length >= minimumArticlesCount,
                isScheduled: this.hasStartDate(activeCommunity),
                isSuperUser: role === Roles.super_admin,
                lastSend: activeCommunity._communityInfo.data.schedule[0].last_send,
                manualSendCutOffTime: manualIssueCutoff,
                minimumArticlesCount,
                modalType: Constants.SCHEDULE_STARTED_MODAL,
                nextIssue: activeCommunity.nextIssue,
                sendDate: this.getMinimumDate(),
                sendTime: strfFormatDate(new Date(), Constants.TIME_FORMAT),
                sendMinimumDate: this.getMinimumDate(),
                sendMaximumDate: this.getMaximumDate(),
                sendMinimumTime: this.getMinimumTime(),
                billingPlan: activeCommunity.billingInfo && activeCommunity.billingInfo.currentPlan,
                maxScheduleDays: maxScheduleDayslimit,
              }, () => {
                this.manualSendDisabled().then((isManualSendDisabled) => {
                  this.setState({
                    isManualSendDisabled,
                  })
                })
              })
            })
        }
      }
    })
  }
  public componentWillUpdate = (nextProps, nextState) => {
    if (JSON.stringify(nextProps.data) === JSON.stringify(this.oldProps) && this.state.isDirty) {
      this.setState({
        isDirty: false,
      })
    }
    if (nextState.isDirty) {
      this.props.setLeavePageWarning({
        leaveWarningMessage: 'If you leave, you will lose your unsaved changes.  Continue?',
      })
    } else {
      this.props.setLeavePageWarning({
        leaveWarningMessage: '',
      })
    }
  }

  public render() {
    if (this.displayCantEdit()) {
      return this.renderCantEdit()
    } else if (!this.oldProps) {
      return this.renderIsLoading()
    } else {
      return this.renderEditor()
    }
  }

  public getManualScheduleDateTimeJSX(timezone: string, disabled: boolean): JSX.Element {
    return <div className={`time-send-action-container ${disabled ? 'disabled' : ''}`}>
      <div className="deliver-time-wrap">
        <div>
          <div className="send-time-row">
            <div className="send-time-column">
              <label className="send-time-label">Deliver on:</label>
              <DatePicker
                className="date-picker"
                onChange={this.handleSendDateChange}
                format="yyyy-MM-dd"
                min={this.state.sendMinimumDate}
                max={this.state.sendMaximumDate}
                value={this.getSendDate()}
              />
            </div>
            <div className="send-time-column">
              <label className="send-time-label">At:</label>
              <div className="time-picker">
                <RasaTimePicker
                  min={this.state.sendMinimumTime}
                  onChange={(e: any) => this.handleSendTimeChange(e)}
                  time={this.getSendTime()}
                  timeZone={timezone}/>
              </div>
            </div>
          </div>
          {!this.isDateRange() || !this.isSendTimeValid() ? (
            <div className="send-time-row mt-3">
              Manual sends cannot be scheduled more than 3 days out
            </div>
          ) : null}
        </div>
        <div className="send-button-container">
          <Button
            onClick={() => this.props.openModal(ManualSendModal.key, {})}
            disabled={this.validateSendDateTime() || !this.isDateRange() || !this.isSendTimeValid()}>
            Generate
          </Button>
        </div>
      </div>
    </div>
  }

  public getManualScheduleMessageJSX() : JSX.Element {
    return this.state.isManualSendDisabled ?
      null : this.canEditManualScheduleSettings()
        ? <div className="manual-schedule-settings-container">
          <ManualSendModal
            data={this.props.modals}
            closeModal={this.props.closeModal}
            saveModal={this.props.saveModal}
            modalType={this.state.modalType}
            onSave={() => this.generateNewsletter()}/>
          <div className="last-sent-container">
            {this.sendNewsletterOn()}
          </div>
        </div>
        : <div className="not-enough-content-notification">
          {`Oh no, you don't have enough content. You need to have at least ${this.state.minimumArticlesCount} articles to send your newsletter.`}
        </div>
  }

  public getManualScheduleSettingsJSX(): JSX.Element {
    if (this.oldProps) {
      const frequency = this.oldProps.frequency === ScheduleFrequency.manually
      const { timezone } = this.props.data
      if (this.canEditManualScheduleSettings()){
        if (frequency){
          const isDisabled = this.props.data.frequency !== ScheduleFrequency.manually || this.isDirty
          return this.getManualScheduleDateTimeJSX(timezone, isDisabled)
        }
      }
    }
    return null
  }

  protected renderEditor() {
      return <div className="schedule-wrapper">
        <div className="modal-wrapper">
          <ScheduleStartedModal data={this.props.modals}
                                closeModal={this.props.closeModal}
                                saveModal={this.props.saveModal}
                                modalType={this.state.modalType}
                                title="Schedule Saved"/>
        </div>
        <div className="schedule-header">
          <div className="header-flex-container">
            <div className="icon-and-text-container">
              <div className="titles-and-description-container">
                <h6 className="title">
                  SCHEDULE
                </h6>
                <div className="sub-title">
                  {'Day & Time'}
                </div>
                <div className="description">
                  Choose the date, time and frequency of when your newsletter will send.
                </div>
              </div>
            </div>
            <div className="header-save-info">
              {this.state.isSaving ?
                <Loading size={SIZES.Small}/> :
              <div className = "done-button clearfix">
                <div className={`done-button ${this.isValid() ? 'clickable-item done-button-enabled' : 'done-button-disabled rasa-noclick'}`}
                    onClick={() => this.saveSchedule()}>
                {this.state.savedSchedule ?
                  <p className="saving-schedule">
                    Schedule Changed!
                  </p>
                  :
                  <p>
                    Save Schedule
                  </p>
                }
                </div>
                {this.isShowMoreDayErrorMsg() &&
                <p className="schedular-save-button-info">Contact <a href="mailto:support@rasa.io">
                  support@rasa.io</a> to schedule more than {this.state.maxScheduleDays} days
                </p> }

                {this.displayNextNewsletter() &&
                <NextNewsletterSendComponent
                  nextIssue={this.props.data.nextIssue}
                  timezone={this.props.data.timezone}
                  className={
                    this.state.savedSchedule ?
                      'next-newsletter-wrapper-saved' :
                      'next-newsletter-wrapper'
                  }/>}
              </div>}
            </div>
          </div>
        </div>
        { this.getManualScheduleSettingsJSX() }
        <div className="schedule-settings-container">
        <div className="clearfix ">
          <FrequencySelector onChange={(e: any) => this.updateFrequency(e)}
                              frequency={this.props.data.frequency}>
          </FrequencySelector>
        </div>
        {this.props.data.frequency !== ScheduleFrequency.manually
        ?
        <div className="standard-settings-container">
          {this.props.data.frequency === ScheduleFrequency.monthly &&
            <div className="clearfix">
              <MultiWeekSelector selectedWeeks={this.props.data.selectedWeeks}
              onChange={(e: any) => this.propertyChanged('selectedWeeks', e)}></MultiWeekSelector>
            </div>
          }
          <div className="clearfix ">
            <MultiDaySelector selectedDays={this.props.data.selectedDays}
                              onChange={(e: any) => this.propertyChanged('selectedDays', e)}>
            </MultiDaySelector>
          </div>
          <div className="clearfix schedule-wrap">
            <div className="schedule-delivery-sub-group schedule-new-group">
              <div className="schedule-header-text ">Delivery Time</div>
              <div className="schedule-detail-text">
                Select your delivery time.</div>
              <div className="delivery-time-wrapper">
                <div className="time-picker">
                  <RasaTimePicker
                    onChange={(e: any) => this.handleDeliveryTimeChange(e)}
                    time={this.props.data.time}
                    timeZone={null}>
                  </RasaTimePicker>
                </div>
                <div className="time-zone">
                  <RasaTimeZone
                    timeZone={this.props.data.timezone || DEFAULT_TIMEZONE}
                    onChange={this.timeZoneChanged} />
                </div>
              </div>
            </div>
            <div className="schedule-delivery-sub-group schedule-new-group">
              <Tooltip openDelay={100} position="top" anchorElement="pointer" className="rasa-grid-tooltip">
                <div className="schedule-header-text ">
                  <span title={this.cutoffTimeTooltip()}>
                    Content Review Cut-Off Time
                  </span>
                </div>
              </Tooltip>
              <div className="schedule-detail-text">
                We will stop pulling new content into your article pool this many hours ahead of time.
              </div>
              <div className="cutoff-offset-container">
                <Input
                  value={this.props.data.cutoffOffset}
                  min={Constants.MIN_CUTOFF_OFFSET}
                  max={Constants.MAX_CUTOFF_OFFSET}
                  type="number"
                  onChange={this.updateOffset}
                  valid={this.state.validOffset}
                  invalid={!this.state.validOffset}
                  />
              </div>
            </div>
            <div className="schedule-delivery-sub-group schedule-new-group">
              <div className="clearfix  schedule-header-text">Pause Schedule</div>
              <div className="schedule-detail-text">Pause your newsletter after it's been activated.</div>
              <div className="clearfix footer">
                <DropDownList data={this.props.data.isActive ?
                  [{text: 'Paused', value: 0}] :
                  [{text: 'Active', value: 1}]}
                              textField="text"
                              defaultItem={this.props.data.isActive ?
                                {text: 'Active', value: 1} :
                                {text: 'Paused', value: 0}}
                              onChange={(e) => this.propertyChanged('isActive', e.target.value.value)}/>
              </div>
              {this.getSchedulePauseModalJSX()}
            </div>
            {this.shouldShowStartDate() && <div className="schedule-delivery-sub-group schedule-new-group">
              <div className="clearfix  schedule-header-text">Select Start Date</div>
              <div className="schedule-detail-text">Your first newsletter will be sent on or after this day.</div>
              <div className="clearfix">
                <DatePicker className="schedule-date-picker"
                            disabled={!this.state.companyInfoProvided}
                            format="yyyy-MM-dd"
                            value={this.props.data.startDate}
                            onChange={(e) => this.propertyChanged('startDate', this.midnight(e.value))}></DatePicker>
              </div>
              {!this.state.companyInfoProvided &&
                <div className="company-info-missing">
                  <p>
                    You need to enter your Company Name and Company Address to start
                    sending your newsletter to comply with&nbsp;
                    <a target="_blank"
                       href={GlobalConstants.RASA_HELP_ANTI_SPAM_EMAIL_DELIVERABILITY}>
                      CAN-SPAM requirements.
                    </a>
                  </p>
                  <p>
                    Visit your&nbsp;
                    <a href={DashboardMenuOption.settings + '/company'}>
                      Newsletter Settings page
                    </a>
                    &nbsp;to enter this information.
                  </p>
                </div>}
            </div>}

          </div>
        </div>
          :
          this.getManualScheduleMessageJSX()
        }
        </div>
      </div>
  }

  protected timeZoneChanged = (e: OnChangeEvent) => {
    this.propertyChanged('timezone', e.selected.key.toString())
  }

  protected updateOffset = (e: React.ChangeEvent<any>) => {
    this.propertyChanged('cutoffOffset', e.target.value)
    const valid = e.target.value >= Constants.MIN_CUTOFF_OFFSET && e.target.value <= Constants.MAX_CUTOFF_OFFSET
    this.setState({ validOffset: valid })
  }

  protected saveSchedule() {
    this.setState({
      isSaving: true
    })
    if (this.isDirty) {
      if (this.state.activeSchedule && !this.props.data.isActive
        && !this.state.pauseScheduleConfirmed) {
        this.setState({ showPauseScheduleModal: true })
        return
      }
      this.saveRecord(this.scheduleId).then((result) => {
        this.sharedStore.refresh().then(() => {
          this.props.propertyChanged('nextIssue', result.nextIssue)
          if (!this.state.isScheduled && this.props.data.startDate) {
            this.props.openModal(ScheduleStartedModal.key, result)
          } else {
            this.isOutsidePreprocessWindow()
          }
          this.setState({
            isSaving: false,
            savedSchedule: true,
            isScheduled: !isNil(this.props.data.startDate),
            pauseScheduleConfirmed: false,
            showPauseScheduleModal: false,
          })
          if (this.props.data.frequency === ScheduleFrequency.manually) {
            this.context.store.dispatch(Flash.showFlashMessage(Constants.SEND_SCHEDULE_MESSAGE))
          } else {
            setTimeout(() => {
              this.setState({savedSchedule: false})
            }, 3000)
          }
          if (this.props.data.startDate) {
            if (!this.props.data.isActive) {
              this.context.store.dispatch(BannerActions.closeBanner(BannerType.COUNTDOWN))
            } else {
              this.context.store.dispatch(BannerActions.openBanner(BannerType.COUNTDOWN))
            }
          }
          if(this.shouldReload()){
            window.location.reload()
          } else {
            this.oldProps = this.props.data
          }
        })
      })
    }
  }

  protected isOutsidePreprocessWindow() {
    if (!isEmpty(this.props.data.nextIssue)) {
      this.setState({
        modalType: Constants.PRE_PROCESS_EDIT_MODAL,
      })
      const now = new Date()
      const selectedDate = new Date(now.toDateString() + ' ' + this.props.data.time)
      const cutoffDate = new Date(selectedDate)
      cutoffDate.setHours(selectedDate.getHours() - (this.props.data.nextIssue.schedule.preprocess_offset / 60))
      if (this.props.data.selectedDays[now.getDay()].selected === true && selectedDate > now && cutoffDate < now) {
        this.props.openModal(ScheduleStartedModal.key, this.props.data)
      }
    }
  }

  protected renderCantEdit() {
    return <div className="schedule-wrapper">
      <div>
        <div className="header-flex-container">
          <div className="section-header-text">
            Schedule Settings
          </div>
        </div>
        {this.props.data.frequency.toLocaleLowerCase() === 'manually'
        ?
        <div className="locked-scheduled-settings">
        {`You have scheduled your newsletter to send ${this.getSendDateTimeText()}.
          You can schedule your next email after this time.`}
        </div>
        :
        <div className="cutoff-window-settings">
          Your schedule settings are unavailable because your newsletter is currently generating.
          <br/> You can make changes to the schedule after today's send.
        </div>}
      </div>
    </div>
  }

  protected renderIsLoading() {
    return (
      <div className="schedule-header">
        <div className="header-flex-container">
          <div className="icon-and-text-container">
            <div className="titles-and-description-container">
              <h6 className="title">SCHEDULE</h6>
              <div className="sub-title">{'Day & Time'}</div>
              <div className="description"></div>
            </div>
          </div>
        </div>
        <div>
          <Loading size={SIZES.Large}/>
        </div>
      </div>
    )
  }

  protected isValid = () => {
    const selectedDays = this.props.data.selectedDays.filter((e: any) => e.selected)
    const selectedWeeks = this.props.data.selectedWeeks.filter((e: any) => e.selected)
    if ((selectedWeeks.length === 0 && this.props.data.frequency === ScheduleFrequency.monthly)
      || (this.state.isManualSendDisabled && !this.state.isSuperUser)) {
      return false
    }
    if (this.isDirty &&
      selectedDays.length &&
      this.state.validOffset) {
      if (selectedDays.length > this.state.maxScheduleDays && !this.state.isSuperUser) {
        return false
      }
      return true
    } else {
      return false
    }
  }

  protected isShowMoreDayErrorMsg = () => {
    const selectedDays = this.props.data.selectedDays.filter((e: any) => e.selected)
    if (!this.state.isSuperUser && (this.state.isManualSendDisabled || (this.isDirty &&
      selectedDays.length &&
      this.state.validOffset && selectedDays.length > this.state.maxScheduleDays))) {
        return true
    }
    return false
  }

  protected updateFrequency = (newFrequency: ScheduleFrequency) => {
    // HACK: Until we really support bi-weekly, at least make sure
    // we hardcode the weeks as either every-week or odd weeks only.
    const newWeeks = '1' // deault to week 1 for any schedule frequency
    const newSelectedWeeks: WeekInfo[] = weekOccurence.createNewFullWeeksOfMonth()
    newSelectedWeeks[0].selected = true

    this.propertiesChanged({
      frequency: newFrequency,
      selectedWeeks: newSelectedWeeks,
      weeks: newWeeks,
    })
  }

  protected cutoffTimeTooltip = () => {
    return 'Set the number of hours before your newsletter send time that you want content to stop pulling into your content pool, so that you have a window to review all of your content. '
  }

  protected inProdCutoffWindow = () => {
    if (this.props.data.nextIssue) {
      const now = new Date()
      const cutoffEnd = parseISO(this.props.data.nextIssue.production_cutoff)
      const sendTime = parseISO(this.props.data.nextIssue.date)

      return (cutoffEnd < now) && (now < sendTime)
    } else {
      return false
    }
  }
  protected handleDeliveryTimeChange(e: any) {
    if (this.props.data.time !== e.time) {
      this.propertyChanged('time', e.time)
    }
  }

  protected midnight(localeDate: Date): Date {
    if (localeDate) {
      const localeDatePart = localeDate.toISOString().slice(0, 10);
      const localeMidnight = `${localeDatePart}T00:00:00`
      const utcMidnight = zonedTimeToUtc(localeMidnight, validateTz(this.props.data.timezone))
      return utcMidnight
    }
    return localeDate
  }

  protected hasStartDate(activeCommunity) {
    // sometimes start_date on activeCommunity is null because of timing so we need to check props
    // isEmpty returns true when given a date object => instead we're using !! for this.props.data.startDate
    return !isEmpty(activeCommunity._communityInfo.data.schedule[0].start_date) || !!this.props.data.startDate
  }

  private shouldReload = (): boolean => {
    return this.oldProps.timezone !== this.props.data.timezone || this.oldProps.frequency !== this.props.data.frequency;
  }

  private shouldShowStartDate() {
    const now = new Date()
    if (this.props.data && this.props.data.startDate && this.props.data.startDate < now) {
      return false
    }
    return true
  }

  private validateCompanyInfo(person, activeCommunity) {
    let communityData: any = {}
    if (activeCommunity.communityInfo.data.community && activeCommunity.communityInfo.data.community.length) {
      communityData = activeCommunity.communityInfo.data.community[0]
    }
    if (!communityData.name || !communityData.address_1 || !communityData.state
      || !communityData.city || !communityData.post_code) {
      if (!this.hasStartDate(activeCommunity)) {
        this.context.store.dispatch(showAppCue(APP_CUE_TYPE.COMPANY_INFO_VALIDATION))
      }
    } else {
      this.setState({
        companyInfoProvided: true,
      })
    }
  }

  private createEntityPropsMap(): EntityPropsMap {
    return new EntityPropsMap(
      [
        {
          entityFieldName: 'timezone',
          propertyName: 'timezone',
          mapType: EntityFieldPropMapType.direct,
        },
        {
          entityFieldName: 'nextIssue',
          propertyName: 'nextIssue',
          mapType: EntityFieldPropMapType.custom,
          mapToPropFunction: (component: ScheduleSettingsComponent, scheduleObject: BaseClientEntity) => {
            return scheduleObject.data.nextIssue
          },
          mapToEntityFieldFunction: (component: ScheduleSettingsComponent) => {
            return null
          },
        },
        {
          entityFieldName: 'is_active',
          propertyName: 'isActive',
          mapType: EntityFieldPropMapType.direct,
        },
        {
          entityFieldName: 'weeks',
          propertyName: 'weeks',
          mapType: EntityFieldPropMapType.direct,
        },
        {
          entityFieldName: 'time',
          propertyName: 'time',
          mapType: EntityFieldPropMapType.direct,
        },
        {
          entityFieldName: 'frequency',
          propertyName: 'frequency',
          mapType: EntityFieldPropMapType.custom,
          mapToPropFunction: (component: ScheduleSettingsComponent, scheduleObject: BaseClientEntity) => {
            // Mapping string from server to enum in component
            return scheduleObject.data.frequency.toLowerCase()
          },
          mapToEntityFieldFunction: (component: ScheduleSettingsComponent) => {
            return this.props.data.frequency
          },
        },
        {
          entityFieldName: 'cutoff_offset',
          propertyName: 'cutoffOffset',
          mapType: EntityFieldPropMapType.custom,
          mapToPropFunction: (component: ScheduleSettingsComponent, scheduleObject: BaseClientEntity) => {
            return scheduleObject.data.cutoff_offset / 60 // database stores in minutes, display in hours to end user
          },
          mapToEntityFieldFunction: (component: ScheduleSettingsComponent) => {
            return this.props.data.cutoffOffset * 60 // database stores in minutes
          },
        },
        {
          entityFieldName: 'days',
          propertyName: 'selectedDays',
          mapType: EntityFieldPropMapType.custom,
          mapToPropFunction: (component: ScheduleSettingsComponent, scheduleObject: BaseClientEntity) => {
            return Weekdays.createLocalDayArrayFromDBString(scheduleObject.data.days)
          },
          mapToEntityFieldFunction: (component: ScheduleSettingsComponent) => {
            return Weekdays.createDBDayFormatString(this.props.data.selectedDays)
          },
        },
        {
          entityFieldName: 'weeks',
          propertyName: 'selectedWeeks',
          mapType: EntityFieldPropMapType.custom,
          mapToPropFunction: (component: ScheduleSettingsComponent, scheduleObject: BaseClientEntity) => {
            return weekOccurence.createLocalWeekArrayFromDBString(scheduleObject.data.weeks )
          },
          mapToEntityFieldFunction: (component: ScheduleSettingsComponent) => {
            return weekOccurence.createDBWeeksFormatString(this.props.data.selectedWeeks)
          },
        },
        {
          entityFieldName: 'start_date',
          propertyName: 'startDate',
          mapType: EntityFieldPropMapType.custom,
          mapToPropFunction: (component: ScheduleSettingsComponent, scheduleObject: BaseClientEntity) => {
            return scheduleObject.data.start_date ? parseISO(scheduleObject.data.start_date.substr(0, 10)) : null
          },
          mapToEntityFieldFunction: (component: ScheduleSettingsComponent) => {
            return this.props.data.startDate ?
              this.props.data.startDate.toISOString() : null
          },
        },
      ], false)
  }

  private onPauseSchedule = () => {
    this.setState({ showPauseScheduleModal: false, pauseScheduleConfirmed: true}, () => {
      this.saveSchedule()
    })
  }

  private onModalClose = () => {
    this.setState({
      showPauseScheduleModal: false,
    })
  }

  private getSchedulePauseModalJSX(): JSX.Element {
    return (
      <div>
        <Modal isOpen={this.state.showPauseScheduleModal}
               className="schedule-pause-confirmation-modal"
               size="lg"
               fade={false}
               centered={true} >
          <ModalHeader>Schedule Pause Confirmation</ModalHeader>
          <ModalBody className="schedule-pause-confirmation-modal-body">
            <div>
              <h4>Are You Sure?</h4>
              <p>When you pause your schedule,
                content will also be paused from coming in until the schedule is unpaused. </p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.onPauseSchedule}>
              Yes
            </Button>
            <Button onClick={this.onModalClose}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }

  private displayNextNewsletter() {
    const isManualSelected = this.props.data.frequency === ScheduleFrequency.manually
    //only display the nextNewsletter box when selected frequency is not Manually & saved
    return !isManualSelected || this.isDirty
  }

  private displayCantEdit() {
    const test1 = ((this.state.activeSchedule && this.inProdCutoffWindow()
    && !this.props.modals[ScheduleStartedModal.key].isOpen))
    // two cases where manually is selected and can't edit:
    // 1. frequency was not manually then user selected manually, clicked send and confirmed in the modal
    // 2. user has already done 1. and comes back to this page
    const test2 = ((!this.oldProps || this.oldProps.frequency === this.props.data.frequency) && 
      this.props.data.frequency === ScheduleFrequency.manually &&
      ((this.isDirty && this.state.isSending) || (!this.isDirty && !!this.state.nextIssue)))
    return test1 || test2
  }

  private getArticleList(communityIdentifier: string, isActive: number = 1) {
    const params: DatasetParam[] = [
      {param: 'isActive', value: isActive},
    ]
    return new Dataset().loadCommunityDataset('communityUpcomingContentPool', communityIdentifier, params)
      .then((r1) => {
        const articleList = r1[0].filter((e: any) => e.is_active)
        return articleList
      })
  }

  private getMinimumArticlesCount(communityIdentifier, emailLayoutId) {
    return this.context.entityMetadata.getEntityObject('email_layout', communityIdentifier, emailLayoutId)
      .then((emailEntityObject: any) => {
        const minimumArticlesCount = emailEntityObject._data.minimum_newsbrief_count || 4
        return Number(minimumArticlesCount)
      })
  }

  private handleSendDateChange = (e: any) => {
    this.setState({
      sendDate: e.value,
    },
    () => this.setState({
      sendMinimumTime: this.getMinimumTime(),
    }),
    )
  }

  private handleSendTimeChange(e: any) {
    this.setState({sendTime: e.time})
  }

  private generateNewsletter() {
    const url: string = AjaxWrapper.getServerUrl() + '/generate-newsletters/' + this.state.communityId
    const payload = {
      sendDateTime: this.getSendDateTime(),
      cutoff: new Date(),
    }
    return AjaxWrapper.ajax(url, HttpMethod.POST, payload)
    .then((result) => {
      if (result && result.StatusCode === 202) {
        this.context.store.dispatch(Flash.showFlashMessage(Constants.SUCCESSFUL_MANUAL_SEND))
        this.setState({isSending: true})
        this.manualSendDisabled()
        window.location.reload()
      }
    })
    .catch((err) => {
      this.context.store.dispatch(Flash.showFlashError(Constants.UNSUCCESSFUL_MANUAL_SEND))
      // eslint-disable-next-line no-console
      console.log('error: ', err)
    })
  }

  private sendNewsletterOn(): string {
    let whenToSendNewsletter: string = '';
    const { timezone } = this.props.data
    if (new Date(this.state.lastSend) > new Date()) {
      whenToSendNewsletter = `Your newsletter will be generated now with the current content.  
      It will be delivered at the time you specify ${formatSendDate(this.state.lastSend, timezone)}`
    } else if (this.oldProps.frequency === ScheduleFrequency.manually) {
      whenToSendNewsletter = `Your newsletter will be generated with the current content on your upcoming content page.
      It will be delivered at the date and time you specify above after you click generate`
    } else {
      whenToSendNewsletter = `Click Save Schedule to change to a manual send and schedule the date and time`
    }
    return whenToSendNewsletter
  }

  private getNewsletterManualIssueCutOff() {
    const url: string = AjaxWrapper.getServerUrl() + `/newsletter-manual-issue/${this.state.communityId}`
    return AjaxWrapper.ajax(url, HttpMethod.GET, {})
    .then((result) => {
      if (result && result.Cutoff) {
         return result.Cutoff
      } else {
        return null
      }
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.log('error: ', err)
    })
  }

  private canEditManualScheduleSettings(): boolean {
    //1- Check if there hasEnoughContent, it is true
    //i- when page is loading first time,
    //ii- when click on Send and refresh the page immediatly. it takes few seconds to clear the articles
    //    till that time span first condition reamins true.
    //2- Saving cutOff time using InsightCache and verifying it against sendMinimumDate,
    //   if difference is less than 1 minute means issue not yet created.
    if (this.state.hasEnoughContent) {
      return !this.state.manualSendCutOffTime ||
        (this.state.manualSendCutOffTime &&
          (Math.abs(+(new Date(this.state.manualSendCutOffTime)) - +(this.state.sendMinimumDate)) / (1000 * 60) > 1 ))
    }
  }

  private getSendDate() {
    return this.state.sendDate
  }

  private getSendTime() {
    return this.state.sendTime
  }

  private validateSendDateTime(): boolean {
    return !this.state.hasEnoughContent || !this.state.sendDate || !this.state.sendTime || this.state.isSending
  }

  private getSendDateTimeText(): string {
    if (!isEmpty(this.state.nextIssue) && this.oldProps.frequency === this.props.data.frequency) {
      return formatSendDate(this.state.nextIssue.date, this.props.data.timezone)
    } else {
      const sendDateTime = this.getSendDateTime()
      const sendDateTimeUtcString = sendDateTime.toUTCString()
      return formatSendDate(sendDateTimeUtcString, this.props.data.timezone)
    }
  }

  private getSendDateTime(): Date {
    const timeArray = this.state.sendTime.split(':')
    const hours = Number(timeArray[0])
    const minutes = Number(timeArray[1])
    const sendDateTime = this.state.sendDate
    sendDateTime.setHours(hours)
    sendDateTime.setMinutes(minutes)
    sendDateTime.setSeconds(0)
    sendDateTime.setMilliseconds(0)
    const timezonedSendDateTimeTime = zonedTimeToUtc(sendDateTime, this.props.data.timezone || DEFAULT_TIMEZONE)
    return timezonedSendDateTimeTime
  }

  private getMinimumTime(): Date {
    const now = new Date()
    const tomorrow = addMinutes(addDays(now, 1), -1)
    if (this.state.sendDate >= tomorrow) {
      now.setHours(0)
      now.setMinutes(0)
      now.setSeconds(0)
      return now
    } else {
      const currentBrowserTime = new Date()
      const timezonedCurrentTime = utcToZonedTime(currentBrowserTime, this.props.data.timezone || DEFAULT_TIMEZONE)
      return timezonedCurrentTime
    }
  }

  private getMinimumDate(): Date {
    return new Date()
  }
  private getMaximumDate(): Date {
    // 72 hours is max for sendgrid. Adding only 2 days so that I don't need to worry about time on 3rd day.
    const curDate = new Date()
    curDate.setDate(curDate.getDate() + 2)
    return curDate
  }

  private manualSendDisabled = (): Promise<boolean> => {
    if (this.state.isSuperUser) {
      return Promise.resolve(false)
    }
    const params = [
      {param: 'minimumSent', value: 1},
      {param: 'startDate', value: formatDateForES(subDays(new Date(), Constants.ISSUE_LOOKBACK_DAYS_FOR_SEND))},
      {param: 'endDate', value: formatDateForES(new Date())},
    ]
    return new Dataset().loadCommunityDataset('communityIssues', this.state.communityId, params).then((issues) => {
      return issues[0].length >= this.state.maxScheduleDays
    })
  }

  private isDateRange = () => {
    const sendDateTime = Math.floor(new Date(this.state.sendDate).getTime() / 1000)
    const minDateTime = Math.floor(new Date(this.state.sendMinimumDate).getTime() / 1000)
    const maxDateTime = new Date(this.state.sendMaximumDate).getTime()
    return sendDateTime >= minDateTime && sendDateTime <= maxDateTime
  }

  private isSendTimeValid = () => {
    const [hours,minutes] = this.state.sendTime.split(':').map(Number)
    const seconds = this.state.sendMinimumTime.getSeconds()
    const milliSeconds = this.state.sendMinimumTime.getMilliseconds()
    const sendMinimumTime = new Date(this.state.sendMinimumTime)
    sendMinimumTime.setHours(hours,minutes,seconds, milliSeconds)
    return sendMinimumTime >= this.state.sendMinimumTime
  }
}

export const ScheduleSettings =
  registerNewComponentWithModals<ScheduleSettingsData>(
    ScheduleSettingsComponent,
    'schedule',
    [ScheduleStartedModal.key, ManualSendModal.key],
    emptyScheduleSettings)
